import './styles/App.css';
import React, {useContext} from 'react';
import TabList from './components/Tab_0_List';
import { AuthProvider, AuthContext } from './context/AuthContext';
import LoginForm from './components/login/LoginForm';
import PopupImportImgBlock from "./components/PopupImportImgBlock";
const App = () => {
    const { isAuthenticated, logout } = useContext(AuthContext)
    return (
                <div>
                    <header>
                        <h1>Relaxia Admin Panel</h1>
                        {isAuthenticated ? <button onClick={logout}>Logout</button> : null}
                    </header>
                    <main>
                        <section id="nested-structure">
                            <h2>Audio Structure</h2>
                            {isAuthenticated ? <TabList /> : <LoginForm />}
                        </section>
                        <PopupImportImgBlock/>
                    </main>
                </div>
    );
};
export default App;
