import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const AudioPlayer = ({ soundUrl }) => {
    const audioRef = useRef(null);
    const progressContainerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const audio = audioRef.current;

        const handlePlayPause = () => setIsPlaying(!audio.paused);
        const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
        const handleLoadedMetadata = () => setDuration(audio.duration);
        const handleEnded = () => setIsPlaying(false);

        // Обработчики событий
        audio.addEventListener('play', handlePlayPause);
        audio.addEventListener('pause', handlePlayPause);
        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        audio.addEventListener('ended', handleEnded);

        // Удаление обработчиков событий при размонтировании компонента
        return () => {
            audio.removeEventListener('play', handlePlayPause);
            audio.removeEventListener('pause', handlePlayPause);
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audio.removeEventListener('ended', handleEnded);
        };
    }, []);

    // Обновление источника звука в элементе <audio> при изменении soundUrl
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = soundUrl;
            audioRef.current.load();  // Перезагрузка источника аудио
        }
    }, [soundUrl]);

    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
        }
    };

    const handleProgressClick = (e) => {
        const width = progressContainerRef.current.clientWidth;
        const clickX = e.nativeEvent.offsetX;
        const newTime = (clickX / width) * duration;
        audioRef.current.currentTime = newTime;
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="audio-player">
            <button className="play-pause" onClick={togglePlayPause}>
                <i className={`fas fa-${isPlaying ? 'pause' : 'play'}`}></i>
            </button>
            <div className="progress-container" ref={progressContainerRef} onClick={handleProgressClick}>
                <div className="progress-bar" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
            </div>
            <div className="time">
                <span className="current-time">{formatTime(currentTime)}</span>
                <span className="duration">{formatTime(duration)}</span>
            </div>
            <audio ref={audioRef}>
                <source src={soundUrl} />
                {/* Альтернативный текст в случае неподдерживаемого формата */}
                Your browser does not support the audio tag.
            </audio>
        </div>
    );
};

AudioPlayer.propTypes = {
    soundUrl: PropTypes.string.isRequired,
};

export default AudioPlayer;
