import React, { createContext, useState, useCallback } from 'react';
import { apiService } from '../services/apiService';
import { useNotification } from '../components/shared/notify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || null);
    const showNotification = useNotification;

    const login = useCallback(async (email, password) => {
        try {
            const response = await apiService.login(email, password);
            localStorage.setItem('authToken', response.access_token);
            apiService.setAuthToken(response.access_token);
            setAuthToken(response.access_token);
            showNotification('Login successful', 'success');
        } catch (error) {
            showNotification('Login failed. Please check your credentials and try again.', 'error');
        }
    }, [showNotification]);

    const logout = () => {
        localStorage.removeItem('authToken');
        setAuthToken(null);
        showNotification('Logout successful', 'success');
    };

    return (
        <AuthContext.Provider value={{ authToken, login, logout, isAuthenticated: !!authToken }}>
            {children}
        </AuthContext.Provider>
    );
};
