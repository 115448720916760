import Notify from 'simple-notify';

export const useNotification = (message, type = 'success') => {
    new Notify({
        status: type,
        title: 'Notification',
        text: message,
        effect: 'fade',
        speed: 300,
        customClass: null,
        customIcon: null,
        showIcon: true,
        showCloseButton: false,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'right top'
    });
};
