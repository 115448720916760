import React from 'react';
import { observer } from 'mobx-react-lite';
import tabStore from '../stores/TabStore';
import getImgUrl from "./shared/getImgUrl";

const PopupImportImgBlock = observer(() => {
    const isHidden = !!!tabStore.imgImportExportUrl;

    return (
        <div id="popup-block" className="popup-block" style={{ display: isHidden ? 'none' : 'flex' }}>
            <img id="popup-block-img" src={getImgUrl(tabStore.imgImportExportUrl)} alt="Image"/>
            <i className="fas fa-fill-drip"></i>
            <span className="close-icon"
            onClick={()=>{tabStore.setSoundImgExport()}}>&times;</span>
        </div>
    );
});

export default PopupImportImgBlock;
