import React, {useCallback, useRef, useState} from 'react';
import SoundList from './Sound_0_List';
import {useNotification} from "./shared/notify";
import tabStore from "../stores/TabStore";
import {debounce} from "lodash";
import {observer} from "mobx-react-lite";
import getImgUrl from "./shared/getImgUrl";

const CategoryList = observer(({ categories, tabIndex, mainCategoryIndex }) => {
    const showNotification = useNotification;
    let inputRef = useRef(null);

    // Состояние видимости для каждой категории
    const [expandedCategories, setExpandedCategories] = useState({});
    // Функция для переключения состояния видимости
    const toggleSoundsVisibility = (categoryId) => {
        setExpandedCategories(prevState => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }));
    };

    const handleEditCategory = async (categoryIndex, dataUpdates) => {
        try {
            await tabStore.updateCategory(tabIndex, categoryIndex, dataUpdates);
            showNotification('Category updated successfully', 'success');
        } catch (error) {
            console.error('Error updating category:', error);
            showNotification('An error occurred while updating the category. Please try again.', 'error');
        }
    };
    const handleDeleteCategory = async (categoryIndex) => {
        const category = categories[categoryIndex];
        if (confirm(`Are you sure you want to delete the category "${category.name}"?`)) {
            try {
                await tabStore.deleteCategory(tabIndex, categoryIndex);
                showNotification('Category deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting category:', error);
                showNotification('An error occurred while deleting the category. Please try again.', 'error');
            }
        }
    };

    // Используем debounce для уменьшения числа API вызовов
    const debouncedEditCategory = useCallback(
        debounce((tabIndex, categoryIndex, newName, newDescription) => {
            handleEditCategory(categoryIndex, {name:newName, description:newDescription});
        }, 600),
        []
    );
    const handleInputChange = (event) => {
        const { className, dataset, value } = event.target;
        const { tab: tabIndex, category: categoryIndex } = dataset;
        const newName = className.includes('category-name') ? value : categories[categoryIndex].name;
        const newDescription = className.includes('category-description') ? value : categories[categoryIndex].description;
        if(newName === categories[categoryIndex].name && newDescription === categories[categoryIndex].description) return;//no changes
        debouncedEditCategory(tabIndex, categoryIndex, newName, newDescription);
    };

    const handleAddSound = async (event, tabIndex, categoryIndex) => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'audio/*,image/*';
        fileInput.multiple = true;
        fileInput.onchange = async function(e) {
            const files = Array.from(e.target.files);
            const audioFiles = files.filter(file => file.type.startsWith('audio'));
            const imgFiles = files.filter(file => file.type.startsWith('image'));
            const category = tabStore.tabs[tabIndex].categories[categoryIndex];
            let soundsData = [];
            const imgFile = imgFiles[0];

            //данный цикл создает обьекты до закгрузки
            for (const soundFile of audioFiles) {
                const fileName = soundFile.name.replace(/\.[^/.]+$/, "");
                const placeholderSound = {
                    name: fileName,
                    duration: 0, // default value, to be updated later
                    imgUrl: '',
                    soundUrl: URL.createObjectURL(soundFile),
                    sortParameter: tabStore.tabs[tabIndex].categories[categoryIndex].sounds.length,
                    status: 'loading'
                };
                const indexSound = category.sounds.push(placeholderSound) - 1;
                soundsData.push({tabIndex, categoryIndex, fileName, soundFile, imgFile, placeholderSound, indexSound})
            }

            //запускает загрузку и заменяет по мере загрузки
            for (const soundFile of soundsData ){
                try {
                    const createdSound = await tabStore.addSound(...Object.values(soundFile));
                    category.sounds[soundFile.indexSound] = createdSound;
                    //tabStore.tabs[tabIndex].categories[categoryIndex].sounds = tabStore.tabs[tabIndex].categories[categoryIndex].sounds.filter(sound => sound.status !== 'loading').concat(createdSound);
                    showNotification('Sound added successfully', 'success');
                } catch (error) {
                    console.error('Error creating sound:', error);
                    tabStore.tabs[tabIndex].categories[categoryIndex].sounds = tabStore.tabs[tabIndex].categories[categoryIndex].sounds.filter(sound => sound !== soundFile.placeholderSound);
                    showNotification('An error occurred while creating the sound. Please try again.', 'error');
                }
            }

        };
        fileInput.click();
    };
    const handleEditTab = async (tabIndex, updates) => { //for set main category
        try {
            await tabStore.editTab(tabIndex, updates);
            showNotification('Tab updated successfully', 'success');
        } catch (error) {
            console.error('Error updating tab:', error);
            showNotification('An error occurred while updating the tab. Please try again.', 'error');
        }

    };

    const openFileDialog = (tabIndex, categoryIndex) => {
        inputRef.current.dataset.tabIndex = tabIndex;
        inputRef.current.dataset.categoryIndex = categoryIndex;
        inputRef.current.click();
    }
    const updateCatImg = async (event) => {
        const tabIndex = inputRef.current.dataset.tabIndex;
        const categoryIndex = inputRef.current.dataset.categoryIndex;
        const imgFile = event.target.files[0];
        if (imgFile) {
            try {
                await tabStore.updateCategory(tabIndex, categoryIndex, {imageFile:imgFile});
                showNotification('Category updated successfully', 'success');
            } catch (error) {
                console.error('Error updating Category:', error);
                showNotification('An error occurred while updating the Category. Please try again.', 'error');
            }
        }
    };

    return (
        <ul className="nested-list category-list">
            <input type="file" ref={inputRef} onChange={updateCatImg} style={{display: 'none'}}/>
            {categories.length === 0 ? (
                <p>No categories in this tab. Click *Add Category* to create one.</p>
            ) : (
                categories.map((category, categoryIndex) => {
                    const categoryId = `category-${tabIndex}-${categoryIndex}`;
                    const isExpanded = expandedCategories[categoryId];
                    const isMain = mainCategoryIndex === category._id;

                    return (
                        <li key={categoryId} className="nested-item category-item">
                            <div className="category-container">
                                <div className="category-info">
                                    <span className={"cat-thumbnail-name-container"}>
                                        <div className={"cat-thumbnail-container"}>
                                            <img src={getImgUrl(category.imgUrl)} alt={category.name} className="cat-thumbnail" />
                                            <button className="edit-cat-img-btn" data-tab={tabIndex} data-category={categoryIndex} title="Edit Image" onClick={()=>openFileDialog(tabIndex, categoryIndex)}>
                                                <i className="fas fa-upload"></i>
                                            </button>
                                        </div>
                                        <input
                                            type="text"
                                            className="category-name"
                                            defaultValue={category.name}
                                            data-tab={tabIndex}
                                            data-category={categoryIndex}
                                            onChange={handleInputChange}
                                        />
                                    </span>
                                    <span>
                                        <button className={isMain?"main-category-btn":"not-main-category-btn"} data-tab={tabIndex} data-category={categoryIndex}
                                                title={isMain? "It's MAIN Category":"Set as MAIN Category"}
                                                onClick={() => handleEditTab(tabIndex,{mainCategoryId:category._id})}>
                                            <i className="fas fa-crown"></i>
                                        </button>
                                        <button className="delete-category-btn" data-tab={tabIndex} data-category={categoryIndex} title="Delete Category"
                                                onClick={() => handleDeleteCategory(categoryIndex)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </span>
                                    <textarea
                                        className="category-description"
                                        data-tab={tabIndex}
                                        data-category={categoryIndex}
                                        onChange={handleInputChange}
                                        defaultValue={category.description || ''}
                                    />
                                    <button
                                        className="toggle-sounds-btn"
                                        onClick={() => toggleSoundsVisibility(categoryId)}
                                    >
                                        {isExpanded ? 'Hide Sounds:' : 'Show Sounds:'} {category.sounds.length}
                                    </button>
                                    <button className="add-sound-btn" data-tab={tabIndex} data-category={categoryIndex}
                                            onClick={(event) => handleAddSound(event, tabIndex, categoryIndex)}>
                                        Add Sound
                                    </button>
                                </div>
                                {isExpanded && (
                                    <SoundList sounds={category.sounds} tabIndex={tabIndex} categoryIndex={categoryIndex} />
                                )}
                            </div>
                        </li>
                    );
                })
            )}
        </ul>
    );
});

export default CategoryList;
